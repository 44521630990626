import React, { Component } from "react";
import TabList from "./components/tabList";
import Clock from "./components/clock";

import ProjectListing from "./components/projectListing";
import AboutMe from "./components/aboutMe";

class App extends Component {
    state = {
        projects: [
            {
                name: "astra",
                github: "https://github.com/Brayzure/astra",
                desc:
                    "In-development event-driven wrapper library for the Discord API. Abstracts away authentication, WebSocket management, cache management, and event handling. More or less abandoned until I have time to bring it up to date with the current spec."
            },
            {
                name: "Portal 2 Credits",
                url: "https://sigterm.dev/portal2",
                desc:
                    "Small application written over a weekend, emulates the Portal 2 credits. Needs an update, the timing is funky in browsers now, especially Firefox. I suspect they changed how setTimeout is implemented."
            },
            {
                name: "Baby Name Trends",
                url: "https://google.com",
                desc:
                    "In-development project to parse SSA name data and display it in a graph to show long term trends. Utilizes the d3.js library for data visualization. Dummy URL given, since the project isn't quite ready."
            }
        ]
    };

    render() {
        return (
            <React.Fragment>
                <TabList>
                    <ProjectListing
                        label="Projects"
                        projects={this.state.projects}
                    />
                    <AboutMe
                        label="About Me"
                        name="Tyler Ricketts"
                        description="Hello! I'm a software developer. Every now and then I can make cool things. I've worked with node.js for 4 years, and I possess a PEGA 8 CSA certification."
                    />
                </TabList>
                <Clock />
            </React.Fragment>
        );
    }
}

export default App;
