import React, { Component } from "react";
import ProjectHeader from "./projectHeader";
import ProjectBody from "./projectBody";
import "./project.css";

class Project extends Component {
    render() {
        return (
            <React.Fragment>
                <ProjectHeader projectName={this.props.project.name} />
                <ProjectBody project={this.props.project} />
            </React.Fragment>
        );
    }
}

export default Project;
