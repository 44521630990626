import React, { Component } from "react";
import Tab from "./tab";
import "./tabList.css";

class TabList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label,
            components: {}
        };

        for (const component of this.props.children) {
            this.state.components[component.props.label] = component;
        }
    }

    render() {
        return (
            <React.Fragment>
                <ol>
                    {this.props.children.map((e) => {
                        return (
                            <Tab
                                label={e.props.label}
                                onClick={this.handleClick.bind(this)}
                                active={e.props.label === this.state.activeTab}
                            />
                        );
                    })}
                </ol>
                <div className="tabComponent">
                    {this.state.components[this.state.activeTab]}
                </div>
            </React.Fragment>
        );
    }

    handleClick(e) {
        this.setState({ activeTab: e });
    }
}

export default TabList;
