import React, { Component } from "react";
import "./aboutMe.css";
import portrait from "../assets/portrait400px.jpg";

class AboutMe extends Component {
    state = {};
    render() {
        return (
            <div className="about">
                <h1>{this.props.name}</h1>
                <div className="aboutInfo">
                    <img src={portrait} alt="Myself rock climbing" />
                    <p>{this.props.description}</p>
                </div>
            </div>
        );
    }
}

export default AboutMe;
