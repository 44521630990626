import React from "react";
import "./projectBody.css";
import ProjectMetadata from "./projectMetadata";

const projectBody = (props) => {
    return (
        <div className="entryBody">
            {props.project.github && (
                <ProjectMetadata
                    type="GitHub"
                    data={props.project.github}
                    shouldLink={true}
                />
            )}
            {props.project.url && (
                <ProjectMetadata
                    type="URL"
                    data={props.project.url}
                    shouldLink={true}
                />
            )}
            <ProjectMetadata type="Description" data={props.project.desc} />
        </div>
    );
};

export default projectBody;
