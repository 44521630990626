import React, { Component } from "react";
import Project from "./project";
import "./projectListing.css";

class ProjectList extends Component {
    render() {
        return this.props.projects.map((e) => {
            return (
                <div className="entry">
                    <Project project={e} />
                </div>
            );
        });
    }
}

export default ProjectList;
