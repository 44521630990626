import React, { Component } from "react";

class Clock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            interval: null,
            currentTime: new Date().toUTCString()
        };
    }

    render() {
        return (
            <p>
                Local Time:{" "}
                {new Date(this.state.currentTime + "-07:00").toString()}
            </p>
        );
    }

    componentDidMount() {
        this.setState({
            interval: setInterval(() => {
                this.setState({ currentTime: new Date().toUTCString() });
            }, 1000)
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }
}

export default Clock;
