import React from "react";

const URL = (props) => {
    return (
        <a href={props.data} target="_blank" rel="noreferrer">
            {props.data}
        </a>
    );
};

const Span = (props) => {
    return <span>{props.data}</span>;
};

const projectMetadata = (props) => {
    return (
        <div>
            <h4>{props.type}</h4>
            {props.shouldLink ? (
                <URL data={props.data} />
            ) : (
                <Span data={props.data} />
            )}
        </div>
    );
};

export default projectMetadata;
