import React, { Component } from "react";
import "./tab.css";

class Tab extends Component {
    render() {
        return (
            <li
                className={this.getClasses()}
                onClick={() => this.props.onClick(this.props.label)}
            >
                <h3>{this.props.label}</h3>
            </li>
        );
    }

    getClasses() {
        let classList = "tab";
        if (this.props.active) classList += " tab-active";
        return classList;
    }
}

export default Tab;
